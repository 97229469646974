@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.banner-img {
    display: block;
    border-radius: 10px;
    overflow: hidden;
    height: calc((80vw - 15px) * 0.61);
    background-size: cover !important;
    background-position: center !important;
}

.banner-slide {
    border-radius: 16px;
    margin-right: 16px;

    &:last-child {
        margin-left: 16px;
    }
}

.banner {
    height: calc((80vw - 15px) * 0.61);
}

.badge {
    position: absolute;
    bottom: 60%;
    width: 54px;
    height: 32px;
    background: $negative;
    border-radius: 14px 0 0 14px;
    right: 0;
    padding: 0;
}

.adv-badge-wrap {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 0;
    background: none;
}

.adv-badge {
    border-radius: 10px;
    background: $secondary;
    padding: 2px 4px;
}

.bottom-popup-mobile {
    border-radius: $mobile-border-radius $mobile-border-radius 0 0 !important;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
    padding-bottom: calc(8px + constant(safe-area-inset-bottom));
    padding-bottom: calc(8px + env(safe-area-inset-bottom));
}
